import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, LAPTOP, MOBILE, TABLET, media,
} from '../../constants/screens';
import { BLACK, BLUE, DARK_GRAY } from '../../constants/colors';

const Introduction = ({
  image: { file: { localFile: { childImageSharp: { sizes } } } },
  title, subTitle, description,
}) => (
  <IntroductionContainer>
    <MainContent>
      <Title>
        {subTitle}
      </Title>
      <SubTitle>
        {title}
      </SubTitle>
      <Text>
        {description}
      </Text>
      <ImageWrapper>
        <Image sizes={sizes} alt="Data and survey methodology" />
      </ImageWrapper>
    </MainContent>
  </IntroductionContainer>
);

const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 300px 5% 150px 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 220px 5% 120px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 150px 5%;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: 1600px) {
    padding: 225px 5% 75px 5%;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
`;

const Title = styled.p`
  font-family: AvenirNextLTProBold;
  font-size: 19px;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${DARK_GRAY};
  margin-bottom: 20px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 17px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 17px;
  }
`;

const SubTitle = styled.h1`
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${BLUE};
  margin-bottom: 30px;
  max-width: 805px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 26px;
    max-width: 600px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const Text = styled.h2`
  font-family: AvenirNextLTPro;
  font-weight: 400;
  font-size: 26px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${BLACK};
  margin-bottom: 125px;
  max-width: 990px;
  
  ${media.laptop`
    max-width: 700px;
  `};
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 20px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 20px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  width: 100%;
  
  > div {
    width: 100%;
  }
  
  ${media.laptop`
    max-width: 700px;
  `};
`;

Introduction.propTypes = {
  image: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Introduction;
