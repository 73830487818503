import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import RoundedButton from '../buttons/roundedButton';
import { DARK_BLUE, WHITE } from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const ContactUs = ({ title, content }) => {
  const url = path([0, 'button', 'url'], content);
  const text = path([0, 'button', 'text'], content);
  return (
    <ContactUsContainer>
      <Title>
        {title}
      </Title>
      { (url && text) && (
        <RoundedButton
          text={text}
          color={WHITE}
          colorWithoutHover={WHITE}
          background="transparent"
          withoutHover
          tag="a"
          href={url}
        />
      )}
    </ContactUsContainer>
  );
};

ContactUs.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 150px 5%;
  background-color: ${DARK_BLUE};
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    padding: 120px 5%;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const Title = styled.h4`
  font-size: 30px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${WHITE};
  max-width: 605px;
  text-transform: uppercase;
  margin-bottom: 60px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 24px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

export default ContactUs;
