import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DescriptionItem from './descriptionItem';

const Description = ({ sections }) => (
  <DescriptionContainer>
    <ItemsWrapper>
      {
          sections.map(({
            id, title, description, image: { file: { localFile: { childImageSharp: { sizes } } } },
          }) => (
            <DescriptionItem
              key={id}
              title={title}
              text={description}
              icon={sizes}
              iconWidth={300}
            />
          ))
        }
    </ItemsWrapper>
  </DescriptionContainer>
);

Description.propTypes = {
  sections: PropTypes.instanceOf(Array).isRequired,
};

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Description;
