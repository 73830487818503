import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { graphql } from 'gatsby';
import Introduction from '../components/methodologyPage/introduction';
import Description from '../components/methodologyPage/description';
import ContactUs from '../components/common/contactUs';
import { DARK_SCHEME } from '../constants/colors';
import withCustomLayout from '../enhancers/withCustomLayout';
import withStickyHeader from '../enhancers/withStickyHeader';

const Methodology = (props) => {
  const {
    data: {
      page: { introduction, sections },
    },
  } = props;

  const contactsSection = sections.filter(({ meta = '' }) => meta.includes('contact'));
  const methodologySections = sections.filter(({ meta = '' }) => !meta.includes('contact'));

  return (
    <div>
      <Introduction {...introduction} />
      <Description sections={methodologySections} />
      {contactsSection && contactsSection[0] && (
        <ContactUs {...contactsSection[0]} />
      )}
    </div>
  );
};

Methodology.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape().isRequired,
  }).isRequired,
};

export default compose(
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(Methodology);

// eslint-disable-next-line
export const query = graphql`
  query MethodologyPageQuery {
    page(url: { eq: "/methodology" }) {
      url
      introduction {
        title
        subTitle
        description
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 820) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
      sections {
        id: _id
        name
        meta
        title
        subTitle
        description
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 300) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
        content {
          id: _id
          icon {
            file {
              localFile {
                publicURL
              }
            }
          }
          title
          lead
          button {
            text
            url
          }
          description {
            html
          }
        }
      }
    }
  }
`;
