import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, LAPTOP, MOBILE, TABLET, media,
} from '../../constants/screens';
import {
  BLACK, BLUE, DARK_BLUE, LIGHT_GRAY_2, TURQUOISE, WHITE,
} from '../../constants/colors';

const DescriptionItem = ({ title, text, icon }) => (
  <DescriptionItemContainer>
    <DescriptionItemContent>
      <TextContainer>
        <Title>
          {title}
        </Title>
        <Text>
          {text}
        </Text>
      </TextContainer>
      <IconContainer>
        <IconWrapper>
          <Image sizes={icon} />
        </IconWrapper>
      </IconContainer>
    </DescriptionItemContent>
  </DescriptionItemContainer>
);

DescriptionItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.shape().isRequired,
};

const DescriptionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 170px 5%;
  
  ${media.laptop`
    padding: 130px 5%;
  `}
  
  &:nth-child(odd) {
    background-color: ${WHITE};
  }
  
  &:nth-child(even) {
    background-color: ${LIGHT_GRAY_2};
    
    > div {
      > div:first-child {
        order: 2;
        
        > * {
          text-align: right;
        }
      }
    
      > div:last-child {
        order: 1;
      }
    }
  }
  
  &:first-child {
    background-color: ${DARK_BLUE};
    
    > div {
      > div:first-child {
        > h4 {
          color: ${TURQUOISE};
        }
        
        > p {
          color: ${WHITE};
        }
      }
    }
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const DescriptionItemContent = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.4;
  
  @media screen and (max-width: ${TABLET}) {
    order: 1 !important;
    align-items: center;
  }
`;

const Title = styled.h4`
  font-size: 33px;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${BLUE};
  margin-bottom: 30px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 24px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center !important;
    max-width: 700px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const Text = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  line-height: normal;
  letter-spacing: normal;
  color: ${BLACK};
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center !important;
    max-width: 750px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 17px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.4;
  
  @media screen and (max-width: ${TABLET}) {
    order: 2 !important;
    width: 100%;
    margin-top: 125px;
  }
`;

const IconWrapper = styled.div`
  max-width: 250px;
  width: 100%;
  height: 100%;
  
  @media screen and (max-width: ${LAPTOP}) {
    max-width: 190px;
  }
`;

export default DescriptionItem;
